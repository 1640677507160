import { useAppContext } from "@context/state";
import { SiteEntry, Dashboard, Home } from "@components/index";
import { useEffect } from "react";
import _ from "lodash";
import { externalLogin } from "@api/whitelabel";
import { settings } from "@whitelabel/whitelabel.preval.js";

export async function getServerSideProps({ query }) {
  let ssoVerified = null;
  let errorResponse = null;
  if (query.sso_token) {
    try {
      const response = await externalLogin(query.sso_token);
      ssoVerified = response.data;
    } catch (error) {
      errorResponse = error.response.data;
    }
  }
  const commonProps = {
    errorResponse,
    showPrimaryNav: true,
    showSecondaryNav: true,
    showMobileNav: true,
    showFooter: true,
    showMobileFooter: true,
  };
  // If user valid, render page normally, else redirect user to proper page
  if (ssoVerified) {
    return {
      props: {
        ssoVerified,
        errorResponse,
        ...commonProps,
      },
    };
  } else {
    if (errorResponse && settings?.redirectUrl) {
      const redirectUrl = new URL(settings?.redirectUrl);
      redirectUrl.searchParams.set("error", JSON.stringify(errorResponse));
      return {
        redirect: {
          permanent: false,
          destination: redirectUrl.toString(),
        },
        props: {
          errorResponse,
          ...commonProps,
        },
      };
    } else {
      return {
        props: {
          ...commonProps,
        },
      };
    }
  }
}

export default function HomePage(props) {
  if (props?.errorResponse) {
    return null;
  }

  const { loggedIn, user, openWalkthroughVideo, handleExternalLogin } =
    useAppContext();

  useEffect(() => {
    //This code fixes an issue for whitelabel users where back button wouldn't trigger rerender due to window history being empty
    if (
      process.env.NEXT_PUBLIC_WHITELABEL === "true" &&
      Object.keys(window.history.state).length === 0
    ) {
      window.history.pushState(
        {
          url: "/",
          as: "/",
          options: { locale: undefined, _shouldResolveHref: false },
          __N: true,
          idx: 0,
        },
        ""
      );
    }
  }, []);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_WHITELABEL === "true") {
      if (user?.email) {
        if (!_.get(user, "walkthrough_video_played")) {
          openWalkthroughVideo();
        }
      }
    }
  }, [user]);

  useEffect(() => {
    async function handleSSO() {
      if (props.ssoVerified) {
        handleExternalLogin(props.ssoVerified);
      }
    }
  }, []);

  // Prevent rendering of elements that require a user to be present.
  if (!loggedIn) {
    return (
      <div className="w-full h-full">
        <SiteEntry />
      </div>
    );
  }

  return (
    <div className="homepage w-full h-full flex flex-col items-center justify-start">
      <Dashboard />
      <Home />
    </div>
  );
}
